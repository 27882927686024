import React from 'react';

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    NumberInput,

    Edit,
    ImageField,
    ImageInput,
    List,
    ReferenceArrayInput,
    DateField,
    SelectArrayInput,
    NumberField,

    SimpleForm,
    TextField,
    TextInput, TabbedForm, FormTab, ReferenceField, ReferenceInput, SelectInput, Filter
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const ReferralsList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="valid" label="Valid"/>
            <TextField source="code" />
            <ReferenceField label="Source" source="source_user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>
            <TextField source="source_redeemed" label="Source Redeemed"/>
            <ReferenceField label="Referred" source="referred_user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>
            <TextField source="referred_redeemed" label="Referred Redeemed"/>


        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.code}` : ''}</span>;
};

export const ReferralsEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="code"/>
            <BooleanInput source="valid"/>
            <BooleanInput source="referred_redeemed"/>
            <BooleanInput source="source_redeemed"/>

            <ReferenceField label="Source" source="source_user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField label="Referred" source="referred_user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>

        </SimpleForm>
    </Edit>
);


export const ReferralsCreate = props => (
    <Create {...props}>

        <SimpleForm>

            <TextInput source="code"/>
            <BooleanInput source="valid"/>
            <BooleanInput source="referred_redeemed" />
            <BooleanInput source="source_redeemed" />

            <TextInput source="source_user" label="Source User ID"/>
            <TextInput source="referred_user" label="Source User ID"/>

        </SimpleForm>
    </Create>

);
