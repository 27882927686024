import React from 'react';
import CommentsList from '../Utility/CommentsList';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DeleteButton,
    NumberInput,
    AutocompleteInput,
    Edit,
    EditButton,
    ImageField,
    ImageInput,
    List,
    ReferenceManyField,
    DateInput,
    ReferenceArrayField,
    ArrayField,
    ReferenceArrayInput,
    DateField,
    SelectArrayInput,
    NumberField,

    SimpleForm,
    TextField,
    TextInput, TabbedForm, FormTab, ReferenceField, ReferenceInput, SelectInput, Filter
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useImageFieldStyles = makeStyles(theme => ({
    image: { // This will override the style of the <img> inside the <div>
        width: 50,
        height: 50,
    }
}));


const optionRenderer = (choice) => {
    if (!choice) {
        return '';
    }
    return `${choice.firstName} ${choice.lastName ? choice.lastName : ""} (${choice.emailAddress})`;
};

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const QuestionsList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="title" />

            <DateField source="lastFeatured"/>
            <BooleanField source="isFeatured"/>

        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const QuestionsEdit = props =>  {
    const imageFieldClasses = useImageFieldStyles();

    return (

        <Edit title={<EditTitle/>} {...props}>
            <TabbedForm>

                <FormTab label="General">

                    <TextInput source="title" fullWidth/>
                    <TextInput source="description"/>
                    <BooleanInput source="isFeatured"/>
                    <DateInput source="lastFeatured"/>


                </FormTab>
                <FormTab label="Responses">

                    <ArrayField
                        label="Posts"
                        reference="posts"
                        source="posts"  fullWidth>
                        <Datagrid >
                            <TextField source="title" multiline />
                            <ImageField label="Image"  classes={imageFieldClasses}  source="user.media.image.url" />

                            <ReferenceField label="Creator" source="user._id" reference="users">
                                <TextField source="firstName" />
                            </ReferenceField>

                        </Datagrid>
                    </ArrayField>

                </FormTab>
            </TabbedForm>
        </Edit>
    );
}


export const QuestionsCreate = props => (
    <Create {...props}>

        <SimpleForm>

            <TextInput source="title" fullWidth/>

            {/*<ReferenceInput*/}
            {/*    fullWidth*/}
            {/*    label="Creator"*/}
            {/*    source="user"*/}
            {/*    reference="users"*/}
            {/*    filterToQuery={(searchText) => ({ search: searchText })}*/}
            {/*>*/}
            {/*    <AutocompleteInput optionText={optionRenderer} />*/}
            {/*</ReferenceInput>*/}

        </SimpleForm>
    </Create>

);
