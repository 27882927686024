import React from 'react';
import {
    List,
    BooleanInput,
    BooleanField,
    Filter,
    ReferenceField,
    Datagrid,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField,
    ImageField,
    DateField,
    Edit,
    SimpleForm,
    ImageInput,
    TextInput, DateInput, NumberInput, Create
} from 'react-admin';

// import RichTextInput from 'ra-input-rich-text';

const required = (message = 'Required') =>
    value => value ? undefined : message;

const tzOffset = new Date().getTimezoneOffset() / 60
const dateParser = v => {
    const regexp = /(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2}):(\d{2})/
    let match = regexp.exec(v)
    if (match === null) return

    let year = match[1]
    let month = match[2]
    let day = match[3]
    let hour = match[4]
    let min = match[5]

    if (tzOffset < 0) {
        const date = new Date(v)
        match = regexp.exec(date.toISOString())
        if (!match) return null
        year = match[1]
        month = match[2]
        day = match[3]
        hour = match[4]
        min = match[5]
    }
    const date = [year, month, day].join('-')
    const time = [hour, min].join(':')
    const result = date + 'T' + time + 'Z'
    return result
}

export const PremiumList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <DateField source="startDate" showTime="true"/>
            <DateField source="expireDate" showTime="true"/>
            <TextField source="emailAddress"/>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>

        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.emailAddress}` : ''}</span>;
};

export const PremiumEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>


            <TextField source="emailAddress" type="email"/>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>
            <DateField source="created" showTime="true"/>
            <DateField source="startDate" showTime="true" />
            <DateInput source="expireDate" showTime="true"/>


        </SimpleForm>
    </Edit>
);

export const PremiumCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="emailAddress" type="email"/>
            <DateInput source="expireDate"/>

        </SimpleForm>
    </Create>
);
