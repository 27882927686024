import React from 'react';
import './App.css';

import LoginPage from './Views/Login';

import authProvider from './Data/authProvider';
import dataUploader from './Data/dataUploader'
import {MamazenTheme} from './Theme'

import AdminAccountIcon from '@material-ui/icons/Security';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/AccountCircle';
import CourseIcon from '@material-ui/icons/FormatListNumbered'
import CoachIcon from '@material-ui/icons/Sports'
import CoachChatIcon from '@material-ui/icons/Chat'
import AgeRangesIcon from '@material-ui/icons/AccessTime'
import EmotionsIcon from '@material-ui/icons/ChildCare'
import SessionsIcon from '@material-ui/icons/Headset'
import MamanotesIcon from '@material-ui/icons/Notes'
import BadgesIcon from '@material-ui/icons/Loyalty'
import MamaStoriesIcon from '@material-ui/icons/Description'
import BackgroundsIcon from '@material-ui/icons/MusicVideo'
import InductionsIcon from '@material-ui/icons/MusicNote'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';


import {PremiumList, PremiumEdit, PremiumCreate} from './Views/PremiumPass'
import {UsersList, UsersEdit} from './Views/Users'
import {AdminUsersList, AdminUsersEdit, AdminUsersCreate} from './Views/AdminUsers'
import {ProgramsList, ProgramEdit, ProgramCreate} from './Views/Programs'
import {CoursesList, CourseEdit, CourseCreate} from './Views/Courses'
import {QuestionsList, QuestionsEdit, QuestionsCreate} from './Views/Questions'
import {CoachesList, CoachEdit, CoachCreate} from './Views/Coaches'
import {CoachChatsList, CoachChatEdit, CoachChatCreate} from './Views/CoachChats'
import {AgeRangeCreate, AgeRangeEdit, AgeRangeList} from './Views/AgeRanges'
import {BackgroundList, BackgroundEdit, BackgroundCreate} from './Views/Backgrounds'
import {ProviderCreate, ProviderList, ProviderEdit} from './Views/Providers'
import {MamanoteList, MamanoteCreate, MamanoteEdit} from './Views/Mamanote'
import {BadgeList, BadgeCreate, BadgeEdit} from './Views/Badges'
import {ReferralsList, ReferralsCreate, ReferralsEdit} from './Views/Referrals'
import {EmotionsCreate, EmotionsEdit, EmotionsList} from './Views/Emotions'
import {SessionsList, SessionsCreate, SessionsEdit} from './Views/Sessions'
import {UserSourcesList, UserSourceCreate, UserSourceEdit} from './Views/UserSources'
import {MamaStoryCreate, MamaStoryEdit, MamaStoryList} from './Views/MamaStories'
import {PostsList, PostsEdit, PostsCreate} from './Views/Posts'
import {Admin, Resource} from 'react-admin';



const App = () => (
    <Admin
        theme={MamazenTheme}
        authProvider={authProvider}
        dataProvider={dataUploader}
        loginPage={LoginPage}
    >

        {permissions => [

            permissions.includes('sessions') ?
                <Resource name="sessions"
                          list={SessionsList}
                          edit={SessionsEdit}
                          create={SessionsCreate}
                          icon={SessionsIcon}
                /> : <Resource name="sessions"/>,

            permissions.includes('programs') ?
                <Resource name="programs"
                          icon={PostIcon}
                          edit={ProgramEdit}
                          create={ProgramCreate}
                          list={ProgramsList}/> : <Resource name="programs"/>,

            permissions.includes('programs') ?
                <Resource name="courses"
                          icon={CourseIcon}
                          edit={CourseEdit}
                          create={CourseCreate}
                          list={CoursesList}/> : <Resource name="courses"/>,


            permissions.includes('emotions') ?
                <Resource name="emotions"
                          list={EmotionsList}
                          edit={EmotionsEdit}
                          create={EmotionsCreate}
                          icon={EmotionsIcon}/> : <Resource name="emotions"/>,


            permissions.includes('ageRanges') ?
                <Resource name="ageRanges"
                          list={AgeRangeList}
                          edit={AgeRangeEdit}
                          create={AgeRangeCreate}
                          icon={AgeRangesIcon}
                /> : <Resource name="ageRanges"/>,

            permissions.includes('mamastories') ?
                <Resource name="mamastories"
                          list={MamaStoryList}
                          edit={MamaStoryEdit}
                          create={MamaStoryCreate}
                          icon={MamaStoriesIcon}
                /> : <Resource name="mamastories"/>,

            permissions.includes('mamanotes') ?
                <Resource name="mamanotes"
                          list={MamanoteList}
                          edit={MamanoteEdit}
                          create={MamanoteCreate}
                          icon={MamanotesIcon}
                /> : <Resource name="mamanotes"/>,

            permissions.includes('mamanotes') ?
                <Resource name="badges"
                          list={BadgeList}
                          edit={BadgeEdit}
                          create={BadgeCreate}
                          icon={BadgesIcon}
                /> : <Resource name="badges"/>,

            permissions.includes('audio') ?
                <Resource name="inductions"
                          list={BackgroundList}
                          edit={BackgroundEdit}
                          create={BackgroundCreate}
                          icon={InductionsIcon}
                /> : <Resource name="inductions"/>,

            permissions.includes('audio') ?
                <Resource name="backgrounds"
                          list={BackgroundList}
                          edit={BackgroundEdit}
                          create={BackgroundCreate}
                          icon={BackgroundsIcon}
                /> : <Resource name="backgrounds"/>,

            permissions.includes('users') ?
                <Resource name="users"
                          list={UsersList}
                          edit={UsersEdit}
                          icon={UserIcon}
                /> : <Resource name="users"/>,

            permissions.includes('adminUsers') ?
            <Resource name="adminUsers"
                      list={AdminUsersList}
                      edit={AdminUsersEdit}
                      create={AdminUsersCreate}
                      icon={AdminAccountIcon}
                      options={{ label:'Admin Users' }}

            /> :   <Resource name="adminUsers"/>,

            permissions.includes('adminUsers') ? <Resource name="userSources"
                      list={UserSourcesList}
                      edit={UserSourceEdit}
                      create={UserSourceCreate}
                      icon={SupervisorAccountIcon}
                      options={{ label:'Sources' }}

            /> :   <Resource name="UserSources"/>,


            permissions.includes('premiumPass') ?
                <Resource name="providers"
                          list={ProviderList}
                          edit={ProviderEdit}
                          create={ProviderCreate}
                          icon={MamanotesIcon}
                /> : <Resource name="providers"/>,

            permissions.includes('premiumPass') ?
                <Resource name="premiumPass"
                          list={PremiumList}
                          edit={PremiumEdit}
                          create={PremiumCreate}
                          icon={AttachMoneyIcon}
                          options={{ label:'Premium Passes' }}
                /> :   <Resource name="premiumPass"/>,


            permissions.includes('referrals') ?
                <Resource name="referrals"
                          list={ReferralsList}
                          edit={ReferralsEdit}
                          create={ReferralsCreate}
                          icon={AllInclusiveIcon}
                          options={{ label:'Referrals' }}
                /> :   <Resource name="referrals"/>,

            <Resource name="medias"

            />,
            <Resource name="posts"
                      edit={PostsEdit}
                      create={PostsCreate}
            />,

            permissions.includes('questions') ?
                <Resource name="questions"
                          list={QuestionsList}
                          edit={QuestionsEdit}
                          create={QuestionsCreate}
                          icon={AgeRangesIcon}
                /> : <Resource name="questions"/>,

            permissions.includes('coaches') ?
                <Resource name="coaches"
                          list={CoachesList}
                          edit={CoachEdit}
                          create={CoachCreate}
                          icon={CoachIcon}
                /> : <Resource name="coaches"/>,

            permissions.includes('coaches') ?
                <Resource name="coachChats"
                          list={CoachChatsList}
                          options={{ label:'Coach Chats' }}
                          edit={CoachChatEdit}
                          create={CoachChatCreate}
                          icon={CoachChatIcon}
                /> : <Resource name="coaches"/>,


        ]}
    </Admin>
);
export default App;
