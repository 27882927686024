import React from 'react';

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    ImageField,
    DateInput,
    ImageInput,
    List,
    ReferenceArrayInput,
    DateField,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput, NumberField, SelectInput, NumberInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const BadgeList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
            <TextField source="postAwardDescription"/>

            <ImageField source="earnedBadgeMedia.image.url" label="Image"/>
            <NumberField source="orderIndex"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const BadgeEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="title"/>
            <SelectInput source="type" label="Usage Frequency" choices={[
                {id: 'sessionsComplete', name: 'Number of Sessions Completed'},
                {id: 'minutesComplete', name: 'Minutes Completed'},
                {id: 'consecutiveDays', name: 'Consecutive Days With Completed Sessions'},
                {id: 'nightSessionsComplete', name: 'Number of Night Sessions Completed'},
                {id: 'daySessionsComplete', name: 'Number of Day Sessions Completed'},
            ]}/>
            <NumberInput source="value"/>
            <NumberInput source="orderIndex"/>

            <TextInput source="preAwardDescription"  validate={required()} fullWidth multiline="true" />
            <TextInput source="postAwardDescription"  validate={required()} fullWidth multiline="true" />
            <TextInput source="celebrationTitle"  validate={required()} fullWidth multiline="true" />
            <TextInput source="celebrationDescription"  validate={required()} fullWidth multiline="true" />
            <TextInput source="celebrationCTAText"  validate={required()} fullWidth multiline="true" />

            <ImageField source="unEarnedBadgeMedia.image.url" label="Un-Earned Badge Image"/>

            <ImageInput source="unEarnedBadgePicture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >

                <ImageField source="src" title="title"/>
            </ImageInput>

            <div style={{ borderTop: "1px solid #ccc", margin: "20px 0" }}></div>



            <ImageField source="earnedBadgeMedia.image.url" label="Earned Badge Image"/>

            <ImageInput source="earnedBadgePicture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >

                <ImageField source="src" title="title"/>
            </ImageInput>




        </SimpleForm>
    </Edit>
);

export const BadgeCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <SelectInput source="type" label="Usage Frequency" choices={[
                {id: 'sessionsComplete', name: 'Number of Sessions Completed'},
                {id: 'minutesComplete', name: 'Minutes Completed'},
                {id: 'consecutiveDays', name: 'Consecutive Days With Completed Sessions'},
                {id: 'nightSessionsComplete', name: 'Number of Night Sessions Completed'},
                {id: 'daySessionsComplete', name: 'Number of Day Sessions Completed'},
            ]}/>
            <NumberInput source="value"/>
            <NumberInput source="orderIndex"/>
        </SimpleForm>
    </Create>
);
