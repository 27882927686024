import React from 'react';

import {
    BooleanField,
    BooleanInput,

    FormDataConsumer,
    Create,
    SelectField,
    Datagrid,
    NumberInput,
    DateInput,
    ReferenceField,
    ArrayField,
    ReferenceInput,
    ArrayInput,
    Edit,
    ImageField,
    SimpleFormIterator,
    ImageInput,
    List,
    ReferenceArrayInput,
    DateField,
    SelectInput,
    NumberField,
    Filter,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput, TabbedForm, FormTab, AutocompleteInput
} from 'react-admin';

Date.prototype.formatMMDDYYYY = function () {
    return (this.getMonth() + 1) +
        "/" + this.getDate() +
        "/" + this.getFullYear();
}

const ContactFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Search by email or name..." style={{minWidth: 250}} source="search" alwaysOn/>
    </Filter>
);

const ChildrenField = ({record}) => {
    return <ul>
        {
            record.children.map(item => {

                let details = item.details ? item.details : ""
                if (details) {


                    details = item.details.map(detail => {
                            let date = detail.dateOfBirth

                            if (date) {

                                date = new Date(date).formatMMDDYYYY()
                            }

                            return "\n " + detail.name + " : " + detail.gender + " : " + date
                        }
                    )
                }
                return <li key={item._id}>

                    {
                        item.count + " " + item.ageRange.title + ": " + details
                    }
                </li>
            })
        }
    </ul>
}
ChildrenField.defaultProps = {
    addLabel: true
};


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const UsersList = props => (
    <List {...props}
          filters={
              // <UsersFilter/>
              <ContactFilter/>
          }
          exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="firstName" label="First Name"/>
            <TextField source="lastName" label="Last Name"/>
            <TextField source="emailAddress" label="Email"/>
            <TextField source="appsflyerId" label="Appsflyer Id"/>

            <ReferenceField
                label='Source'
                source='userSource'
                reference='userSources'
            >
                <TextField source='title'/>
            </ReferenceField>
            <DateField source="created" showTime="true"/>
            <NumberField label="Sessions Completed" source="sessionStatistics.totalSessions"/>
            <BooleanField source="subscribed"/>

            <SelectField source="subscribedType" label="Subscription Type" choices={[


                {id: 'com.mamazen.app.annual.dev', name: 'iOS DEV Annual'},
                {id: 'com.mamazen.app.main.full.android', name: 'Android Monthly'},
                {id: 'com.mamazen.app.main.full', name: 'iOS Monthly'},
                {id: 'com.mamazen.app.main', name: 'iOS Monthly'},
                {id: 'com.mamazen.app.main.yearly', name: 'iOS Annual'},
                {id: 'com.mamazen.app.main.lifetime', name: 'iOS Lifetime'},
            ]}/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.firstName}` : ''}</span>;
};

export const UsersEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <TabbedForm>

            <FormTab label="Provider">

                <TextInput source="provider.email" label="Provider Email"/>
                <TextField source="appsflyerId" label="Appsflyer Id"/>
                <SelectInput  source="provider.state" label="Verified State" choices={[
                    {id: 'new', name: 'New'},
                    {id: 'requested', name: 'Requested'},
                    {id: 'expired', name: 'Expired'},
                    {id: 'verified', name: 'Verified'},

                ]}/>
                <DateInput source="provider.requestedOn" showTime="true"/>
                <ReferenceField
                    
                    label='Provider'
                    source='provider.provider'
                    reference='providers'
                >
                    <TextField source='title'/>
                </ReferenceField>
                <BooleanField valueLabelTrue="Active" valueLabelFalse="Inactive" source="provider.active" label="Provider Active"/>
            </FormTab>

            <FormTab label="General">
                <BooleanInput label="Social Moderator" source="isAdmin"/>
                <ChildrenField source="children"/>
                <DateField source="created" showTime="true"/>
                <BooleanField source="subscribed"/>
                <BooleanField source="adminSubscribed"/>
                <BooleanField source="webSubscribed"/>
                <TextField source="subscribedType" label="Subscription Type"/>

                <ReferenceField
                    label='Source'
                    source='userSource'
                    reference='userSources'
                >
                    <TextField source='title'/>
                </ReferenceField>

                <SelectField source="subscribedType" label="Subscription Type" choices={[
                    {id: 'com.mamazen.app.annual.dev', name: 'iOS DEV Annual'},
                    {id: 'com.mamazen.app.main.full.android', name: 'Android Monthly'},
                    {id: 'com.mamazen.app.main.full', name: 'iOS Monthly'},
                    {id: 'com.mamazen.app.main', name: 'iOS Monthly'},
                    {id: 'com.mamazen.app.main.yearly', name: 'iOS Annual'},
                    {id: 'com.mamazen.app.main.lifetime', name: 'iOS Lifetime'},
                ]}/>


                <TextInput source="firstName" validate={required()}/>
                <TextInput source="lastName"/>
                <TextInput source="referralCode"/>
                <BooleanInput source="referralCodeReward"/>

                <NumberInput label="Seconds Completed" source="sessionStatistics.totalSeconds"/>
                <NumberInput label="Sessions Completed" source="sessionStatistics.totalSessions"/>

                <TextField source="login"/>
                <TextField source="emailAddress" label="Email"/>
                <TextField source="username"/>
                {/*<ArrayField source="children">*/}
                {/*    <Datagrid>*/}
                {/*        <NumberField source="count"/>*/}
                {/*        <TextField source="ageRange.title" label="AgeRange"/>*/}

                {/*    </Datagrid>*/}
                {/*</ArrayField>*/}
            </FormTab>
            <FormTab label="Preferences">
                <ReferenceArrayInput source="emotions" reference="emotions" label="Target Emotions" fullWidth>
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
                <SelectInput source="frequency" label="Usage Frequency" choices={[
                    {id: 'once_wk', name: 'Once a Week'},
                    {id: 'twice_wk', name: 'Twice a Week'},
                    {id: 'three_wk', name: 'Three or More a Week'},
                ]}/>

                <BooleanInput source="adminSubscribed"/>
                <BooleanInput source="dadZone"/>

                <BooleanInput label="Push Notifications" source="preferences.pushNotifications"/>
                <BooleanInput label="Allow Reminders" source="preferences.allowReminders"/>
                <BooleanInput label="Live Session Reminders" source="preferences.liveSessionReminders"/>
                <BooleanInput label="Add Reminders To Calendar" source="preferences.addRemindersToCalendar"/>


            </FormTab>
            <FormTab label="Auth">

                <ArrayInput source="firebaseUserIds">
                    <SimpleFormIterator>
                        <TextInput/>
                    </SimpleFormIterator>
                </ArrayInput>


                <ArrayInput source="firebaseProviders">
                    <SimpleFormIterator>

                        <FormDataConsumer>
                            {({getSource, scopedFormData}) => {
                                return (
                                    [
                                        <br/>,
                                        "FirebaseUID: " + scopedFormData?.firebaseUID + "\n",
                                        <br/>,
                                        "Provider: " + scopedFormData?.providerId,
                                        <br/>,
                                        "email: " + scopedFormData?.email,
                                        <br/>,
                                        "phone: " + scopedFormData?.phone,
                                        <br/>,
                                        "uid: " + scopedFormData?.uid,
                                        <br/>,
                                    ]
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>


            <FormTab label="Image">
                <ImageField source="media.image.url" label="Current Image"/>
                <ImageInput source="picture" label="Upload new image" accept="image/*"
                            multiple={false}
                            placeholder={<p>Click here or Drop your file here</p>}
                >
                    <ImageField source="src" title="title" label="New Image"/>
                </ImageInput>
            </FormTab>

            <FormTab label="Coaches">

                <ArrayInput source="coachChatSummary">
                    <SimpleFormIterator>

                        <ReferenceInput
                            label="Coach"
                            source="coach"
                            reference="coaches"
                            filterToQuery={searchText => ({ name: searchText })}
                            allowEmpty={true}
                            alwaysOn
                        >
                            <AutocompleteInput optionText={(record) => `${record?.name} - ${record?.title}`} />

                        </ReferenceInput>

                        <ReferenceField label="Chat" source="latestChat" reference="coachChats">
                            <TextField source="summary" />
                        </ReferenceField>

                        <TextInput source="latestChat"/>
                        <TextInput source="latestMessage"/>

                        <DateInput source="lastUpdate" label="Last Updated At" />
                    </SimpleFormIterator>
                </ArrayInput>

            </FormTab>
        </TabbedForm>
    </Edit>
);
