import React from 'react';

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    ImageField,
    DateInput,
    ImageInput,
    List,
    ReferenceArrayInput,
    DateField,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const MamanoteList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="lastFeaturedDate_mmddyyyy"/>
            <BooleanField source="active"/>

            <ImageField source="media.image.url" label="Image"/>
            <TextField multiline="true" source="description" label="Message"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const MamanoteEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="description"  validate={required()} fullWidth multiline="true" fullWidth  label="Message"/>
            <BooleanInput source="active"/>
            <TextInput source="lastFeaturedDate_mmddyyyy"/>

            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >

                <ImageField source="src" title="title"/>
            </ImageInput>

        </SimpleForm>
    </Edit>
);

export const MamanoteCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="description"  validate={required()} fullWidth multiline="true" fullWidth  label="Message"/>
            <BooleanInput source="active"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
