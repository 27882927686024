import React from 'react';

import {
    BooleanField,
    DateField,
    BooleanInput,
    NumberField,
    NumberInput,
    Create,
    Datagrid,
    Edit,
    ImageField,
    ImageInput,
    List,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const UserSourcesList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <DateField source="created"/>
            <ImageField source="media.image.url" label="Image"/>
            <TextField source="title"/>
            <NumberField source="orderIndex"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const UserSourceEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>


            <TextInput source="title"/>
            <NumberInput source="orderIndex"/>
            <TextInput source="purchaseUrl"/>
            <BooleanInput source="isProvider"/>
            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>

        </SimpleForm>
    </Edit>
);

export const UserSourceCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <TextInput source="purchaseUrl"/>
            <BooleanInput source="isProvider"/>
            <ImageInput source="picture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
