import React from 'react';
import {
    List,
    BooleanInput,
    BooleanField,
    DateField,
    Filter,
    Datagrid,
    TextField,
    ImageField,
    Edit,
    SimpleForm,
    NumberField,
    ImageInput,
    TextInput, DateInput, NumberInput, Create, FormTab
} from 'react-admin';

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const EmotionsList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <NumberField source="orderIndex" />
            <BooleanField source="active"/>
            <BooleanField source="assesmentAllowed"/>
            <BooleanField source="childEmotion"/>
            <ImageField source="media.image.url" label="Image"/>
            <TextField source="title"/>
            <TextField source="title_positive"/>

        </Datagrid>
    </List>
);


const EditTitle = ({ record }) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const EmotionsEdit = props => (
    <Edit title={<EditTitle />} {...props}>
        <SimpleForm>

            <TextInput source="title" />
            <TextInput source="title_positive" />
            <TextInput source="description" multiline="true" fullWidth/>
            <BooleanInput source="active"/>
            <BooleanInput source="showOnOnboarding"/>
            <BooleanInput source="childEmotion"/>
            <BooleanInput source="showOnExplorePage"/>
            <BooleanInput source="showOnProfile"/>

            <br/>
            <hr fullWidth/>
            <br/>

            <BooleanInput source="assesmentAllowed"/>
            <NumberInput source="assesmentOrderIndex" />

            <TextInput label="Assesment Title" source="assesmentHighTitle" />

            <br/>
            <hr fullWidth/>
            <br/>


            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>
            <NumberInput source="orderIndex" />
        </SimpleForm>
    </Edit>
);

export const EmotionsCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <TextInput source="title_positive" />
            <TextInput source="description"  multiline fullWidth/>
            <BooleanInput source="active"/>

            <BooleanInput source="showOnOnboarding"/>
            <BooleanInput source="childEmotion"/>

            <BooleanInput source="showOnExplorePage"/>


            <ImageInput source="picture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
