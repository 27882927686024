import React from 'react';

import {
    BooleanField,
    DateField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    ImageField,
    ImageInput,
    NumberInput,
    List,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput, NumberField, ArrayInput, SimpleFormIterator, RadioButtonGroupInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const CoachesList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">

            <NumberField source="orderIndex" />
            <ImageField source="media.image.url" label="Image"/>
            <TextField source="name"/>
            <TextField source="title"/>
            <BooleanField source="active"/>
            <TextField multiline="true" source="bio"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.name}` : ''}</span>;
};

export const CoachEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="name"/>
            <TextInput source="title"/>
            <TextInput source="bio" multiline="true" fullWidth/>
            <NumberInput source="orderIndex" />
            <BooleanInput source="active"/>
            <BooleanInput source="utilizeUserData"/>
            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>

            <ArrayInput source="seedQuestions">
                <SimpleFormIterator>
                    <TextInput source="question" label="Question" fullWidth/>


                </SimpleFormIterator>
            </ArrayInput>
            <TextInput
                source="instructions"
                multiline
                fullWidth
                minRows={4} // Adjust the number of rows as needed
            />

            <TextInput
                source="firstMessagePrompt"
                multiline
                fullWidth
                minRows={4} // Adjust the number of rows as needed
            />

            <TextInput
                source="newDayMessagePrompt"
                multiline
                fullWidth
                minRows={4} // Adjust the number of rows as needed
            />

        </SimpleForm>
    </Edit>
);

export const CoachCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="name"/>
            <TextInput source="title"/>
            <TextInput source="bio" multiline="true" fullWidth/>
            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>

            <ArrayInput source="seedQuestions">
                <SimpleFormIterator>
                    <TextInput source="question" label="Question" fullWidth/>


                </SimpleFormIterator>
            </ArrayInput>

            <TextInput
                source="instructions"
                multiline
                fullWidth
                minRows={4} // Adjust the number of rows as needed
            />

        </SimpleForm>
    </Create>
);
