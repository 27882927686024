import React from 'react';
import {
    List,
    BooleanInput,
    BooleanField,
    Filter,
    Datagrid,
    ReferenceArrayInput,
    SelectArrayInput,
    TextField,
    ImageField,
    DateField,
    Edit,
    SimpleForm,
    ImageInput,
    TextInput, DateInput, NumberInput, Create
} from 'react-admin';

// import RichTextInput from 'ra-input-rich-text';

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const PostsList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
            <TextField source="description"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const PostsEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="title"/>



        </SimpleForm>
    </Edit>
);

export const PostsCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>

        </SimpleForm>
    </Create>
);