import React from 'react';

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    NumberInput,
    Edit,
    ImageField,
    ImageInput,
    List,
    ReferenceArrayInput,
    DateField,
    SelectArrayInput,
    NumberField,

    SimpleForm,
    TextField,
    TextInput, TabbedForm, FormTab
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const AdminUsersList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="username" label="Name"/>
            <TextField source="permissions" label="Permissions"/>

        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.username}` : ''}</span>;
};

export const AdminUsersEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="username"/>
            <TextInput source="password"/>

            <SelectArrayInput source="permissions" label="Permissions" choices={[
                {id: 'users', name: 'Users'},
                {id: 'goals', name: 'Goals'},
                {id: 'emotions', name: 'Emotions'},
                {id: 'sessions', name: 'Sessions'},
                {id: 'mamanotes', name: 'Mama Notes'},
                {id: 'audio', name: 'Audio (Background/Inductions)'},

                {id: 'mamastories', name: 'Mama Stories'},
                {id: 'programs', name: 'Programs'},
                {id: 'ageRanges', name: 'Age Ranges'},
                {id: 'adminUsers', name: 'Create Other Admins'},
                {id: 'premiumPass', name: 'Manage Premium Passes'},
                {id: 'referrals', name: 'Manage Referrals'},
                {id: 'questions', name: 'Community'},
                {id: 'coaches', name: 'Coaches'},

            ]}/>
        </SimpleForm>
    </Edit>
);


export const AdminUsersCreate = props => (
    <Create {...props}>

        <SimpleForm>

            <TextInput source="username"/>
            <TextInput source="password"/>


            <SelectArrayInput source="permissions" label="Permissions" choices={[
                {id: 'users', name: 'Users'},
                {id: 'goals', name: 'Goals'},
                {id: 'emotions', name: 'Emotions'},
                {id: 'sessions', name: 'Sessions'},
                {id: 'mamanotes', name: 'Mama Notes'},
                {id: 'mamastories', name: 'Mama Stories'},
                {id: 'audio', name: 'Audio (Background/Inductions)'},
                {id: 'programs', name: 'Programs'},
                {id: 'ageRanges', name: 'Age Ranges'},
                {id: 'adminUsers', name: 'Create Other Admins'},
                {id: 'premiumPass', name: 'Manage Premium Passes'},
                {id: 'referrals', name: 'Manage Referrals'},
                {id: 'questions', name: 'Community'},
                {id: 'coaches', name: 'Coaches'},
            ]}/>

        </SimpleForm>
    </Create>

);
