import React, { useState } from 'react'; // Import useState from React
import {
    FunctionField,
    BooleanField,
    DateField,
    RadioButtonGroupInput,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    ImageField,
    ImageInput,
    NumberInput,
    SelectInput,
    List,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput,
    NumberField,
    ReferenceField,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput,
    ReferenceInput,
    SelectField
} from 'react-admin';

const ResizableTextInput = ({ source, label }) => {
    const [value, setValue] = useState('');
    const [height, setHeight] = useState(60); // Minimum height in pixels

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);

        // Temporary textarea to calculate scroll height
        const tempTextArea = document.createElement('textarea');
        tempTextArea.style.visibility = 'hidden';
        tempTextArea.style.position = 'absolute';
        tempTextArea.style.width = '100%';
        tempTextArea.value = newValue;

        document.body.appendChild(tempTextArea);
        const newHeight = tempTextArea.scrollHeight; // Calculate height based on content
        setHeight(newHeight); // Set height
        document.body.removeChild(tempTextArea);
    };

    return (
        <TextInput
            source={source}
            label={label}
            multiline
            fullWidth
            value={value}
            onChange={handleChange}
            inputProps={{ style: { height: `${height}px` } }} // Adjust height dynamically
        />
    );
};

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const CoachChatsList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">

            <DateField source="created" showTime="true"/>
            <DateField source="updated" showTime="true"/>

            <TextField source="summary"/>
            <BooleanField source="latest"/>
            <BooleanField source="active"/>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField label="Coach" source="coach" reference="coaches">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.summary}` : ''}</span>;
};

export const CoachChatEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>


            <ReferenceInput
                label="User"
                source="user"
                reference="users"
                filterToQuery={searchText => ({ search: searchText })}
                allowEmpty={true}
                alwaysOn
            >
                <AutocompleteInput optionText={(record) => record?.firstName ? `${record?.firstName} - ${record?.emailAddress}` : 'Remove'} />

            </ReferenceInput>

            <ReferenceInput
                label="Coach"
                source="coach"
                reference="coaches"
                filterToQuery={searchText => ({ name: searchText })}
                allowEmpty={true}
                alwaysOn
            >
                <AutocompleteInput optionText={(record) => record?.name ? `${record?.name} - ${record?.title}` : 'Remove'} />

            </ReferenceInput>
            <BooleanInput source="latest"/>
            <BooleanInput source="active"/>
            <DateField source="created" showTime="true"/>
            <TextInput source="summary"/>
            <ArrayInput source="messages">
                <SimpleFormIterator>
                    <ResizableTextInput source="message" label="Message"/>
                    <RadioButtonGroupInput
                        source="type"
                        label="Author"
                        choices={[
                            { id: 'user', name: 'User' },
                            { id: 'coach', name: 'Coach' },
                        ]}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const MyComponent = () => (
    <ReferenceField label="User" source="user" reference="users">
        <FunctionField render={record => `${record?.firstName} - ${record?.emailAddress}`} />
    </ReferenceField>
);
export const CoachChatCreate = props => (
    <Create {...props}>
        <SimpleForm>


            <ReferenceInput
                label="User"
                source="user"
                reference="users"
                filterToQuery={searchText => ({ search: searchText })}
                allowEmpty={true}
                alwaysOn
            >
                <AutocompleteInput optionText={(record) => `${record?.firstName} - ${record?.emailAddress}`} />

            </ReferenceInput>

            <ReferenceInput
                label="Coach"
                source="coach"
                reference="coaches"
                filterToQuery={searchText => ({ name: searchText })}
                allowEmpty={true}
                alwaysOn
            >
                <AutocompleteInput optionText={(record) => `${record?.name} - ${record?.title}`} />

            </ReferenceInput>


            <DateField source="created" showTime="true"/>
            <TextInput source="summary"/>
            <ArrayInput source="messages">
                <SimpleFormIterator>
                    <TextInput source="message" label="Message" fullWidth/>
                    <RadioButtonGroupInput
                        source="type"
                        label="Author"
                        choices={[
                            { id: 'user', name: 'User' },
                            { id: 'coach', name: 'Coach' },
                        ]}
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
