import React from 'react';

import {
    BooleanField,
    BooleanInput,
    Create,
    DateField,
    Datagrid,
    Edit, FileField, FileInput,
    ImageField,
    ImageInput,
    List,
    NumberField,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import AudioPlayer from "../Utility/AudioPlayer";

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const MamaStoryList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <DateField source="created"/>
            <BooleanField source="active"/>
            <NumberField source="orderIndex"/>
            <TextField source="title"  label="Title"/>
            <TextField source="timeEstimate" label="Time Estimate"/>
            <ImageField source="media.image.url" label="Image"/>

        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const MamaStoryEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="title" validate={required()}  label="Title"/>
            <TextInput source="timeEstimate" validate={required()}  label="Time Estimate"/>
            <RichTextInput source="description" multiline="true" fullWidth  label="Message"/>
            <BooleanInput source="active"/>

            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>

            <br/>
            <AudioPlayer source="media.audio.url" label="Audio: "/>
            <FileInput source="basicAudio" label="Replace Audio" accept=".mp3">
                <FileField source="src" title="title"/>
            </FileInput>
            <hr fullWidth/>
            <br/>

        </SimpleForm>
    </Edit>
);

export const MamaStoryCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title" validate={required()}  label="Title"/>
            <TextInput source="timeEstimate" validate={required()}  label="Time Estimate"/>
            <RichTextInput source="description" multiline="true" fullWidth  label="Message"/>
            <BooleanInput source="active"/>
            <ImageInput source="picture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >
                <ImageField source="src" title="title"/>
            </ImageInput>

            <br/>
            <FileInput source="basicAudio" label="Select Audio File:" accept=".mp3">
                <FileField source="src" title="title"/>
            </FileInput>
            <hr fullWidth/>
            <br/>
        </SimpleForm>
    </Create>
);
