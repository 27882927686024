// in src/MyUrlField.js
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import {makeStyles} from '@material-ui/core/styles';

import {
    TextField
} from 'react-admin';


const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2,
    },
});

function getProperty(propertyName, object) {

    var parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this,
        foundProperty = false;

    for (i = 0; i < length; i++) {
        let part = parts[i]
        if (property[part] !== undefined && property[part] !== null) {
            property = property[part];
            foundProperty = true

        }
    }

    let fileName = "No Audio Uploaded"
    if (foundProperty && (typeof property === 'string' || property instanceof String)) {

        fileName = property.split("/").slice(-1)[0]
    }

    let result = { path: property, name: fileName}

    return result
}

const MyUrlField = ({record = {}, source, label}) => {
    const classes = useStyles();
    let result = getProperty(source, record);
    let path = result.path
    let filename = result.name

    return <a>
        {label}
        <br/>

        <br/>
        <ReactAudioPlayer
            src={path}
            controls
        />
        <br/>
        {}
        <br/>
        {filename}
        {/*<TextField src={path} />*/}

        <br/>

    </a>


}
export default MyUrlField;
