const axios = require('axios').default;

var baseUrl = 'http://localhost:3000/v1'
var environment = process.env.REACT_APP_API
if (environment === 'dev') {
    baseUrl = 'https://dev-api.mamazen.com/v1'
} else if (environment === 'prod') {
    baseUrl = 'https://api.mamazen.com/v1'
}


export default {
    // called when the user attempts to log in
    login: ({username, password}) => {

        return new Promise((resolve, reject) => {

            let data = {
                username: username,
                password: password
            }
            // console.log(`baseUrl: ${baseUrl}`)
            // console.log(`requesting: ${JSON.stringify(data, null, 2)}`)
            axios.post(`${baseUrl}/auth/admin`, data)
                .then(response => {
                    // console.log(`response: ${JSON.stringify(response, null, 2)}`)
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    localStorage.setItem('username', username);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('password', password);
                    localStorage.setItem('permissions', response.data.permissions);
                    resolve()
                })
                .catch(function (error) {
                    // console.log(error);
                    reject(error)
                });


        })
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('username') && localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () =>  {

        return new Promise((resolve, reject) => {

            let username = localStorage.getItem('username');
            let password = localStorage.getItem('password');
            let data = {
                username: username,
                password: password
            }


            axios.post(`${baseUrl}/auth/admin`, data)
                .then(response => {
                    // console.log(`response: ${JSON.stringify(response, null, 2)}`)
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    localStorage.setItem('username', username);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('password', password);
                    localStorage.setItem('permissions', response.data.permissions);

                    resolve(response.data.permissions)
                })
                .catch(function (error) {
                    // console.log(error);
                    reject(error)
                });

        })


    },
};
