import React from 'react';
import AudioPlayer from '../Utility/AudioPlayer'
import {
    BooleanField,
    BooleanInput,
    Create,
    DateField,
    Datagrid,
    Edit, FileField, FileInput, FormTab,
    ImageField,
    ImageInput,
    List,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const BackgroundList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <DateField source="created"/>
            <BooleanField source="active"/>
            <TextField source="title"/>
            {/*<ImageField source="media.image.url" label="Image"/>*/}
            <AudioPlayer source="media.audio.url" label="Audio"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const BackgroundEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="title"/>

            <BooleanInput source="active"/>

            <br/>
            <AudioPlayer source="media.audio.url" label="Audio: "/>
            <FileInput source="basicAudio" label="Replace Audio" accept=".mp3">
                <FileField source="src" title="title"/>
            </FileInput>
            <hr fullWidth/>
            <br/>
        </SimpleForm>
    </Edit>
);

export const BackgroundCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>

            <BooleanInput source="active"/>

            <br/>
            <FileInput source="basicAudio" label="Select Audio File:" accept=".mp3">
                <FileField source="src" title="title"/>
            </FileInput>
            <hr fullWidth/>
            <br/>
        </SimpleForm>
    </Create>
);
