import React from 'react';

import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    NumberField,
    Edit,
    ImageField,
    ImageInput,
    NumberInput,
    List,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


const required = (message = 'Required') =>
    value => value ? undefined : message;

export const AgeRangeList = props => (
    <List {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <BooleanField source="active"/>
            <TextField source="fullTitle"/>
            <TextField source="fullTitle_single"/>
            <TextField source="fullTitle_plural"/>
            <TextField source="startAgeTitle"/>
            <TextField source="endAgeTitle"/>
        </Datagrid>
    </List>
);

const EditTitle = ({record}) => {
    return <span>Edit: {record ? `${record.title}` : ''}</span>;
};

export const AgeRangeEdit = props => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <TextInput source="title_single"/>
            <TextInput source="title_plural"/>
            <TextInput source="description" multiline="true" fullWidth/>
            <TextInput source="startAgeTitle"/>
            <NumberInput source="startAgeMonths"/>
            <TextInput source="endAgeTitle"/>
            <NumberInput source="endAgeMonths"/>
            <BooleanInput source="active"/>



            <ImageField source="media.image.url" label="Current Image"/>

            <ImageInput source="picture" label="Upload new image" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}
            >
                <ImageField source="src" title="title" label="New Image"/>
            </ImageInput>

        </SimpleForm>
    </Edit>
);

export const AgeRangeCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="title"/>
            <TextInput source="title_plural"/>
            <TextInput source="description"/>
            <TextInput source="startAgeTitle"/>
            <NumberInput source="startAgeMonths"/>
            <TextInput source="endAgeTitle"/>
            <NumberInput source="endAgeMonths"/>
            <BooleanInput source="active"/>


            <ImageInput source="picture" label="Image To Upload" accept="image/*"
                        multiple={false}
                        placeholder={<p>Click here or Drop your file here</p>}

            >
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);
